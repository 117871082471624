import axios from 'axios';
import { initializeApp } from 'firebase/app';
import {
  getAuth, signInWithCustomToken, updatePassword as fbUpdatePassword,
  reauthenticateWithCredential as fbReauthenticateWithCredential,
  signOut as fbSignOut, EmailAuthProvider, sendPasswordResetEmail as fbSendPasswordResetEmail,
} from 'firebase/auth';
import {
  getDatabase, ref as fbDbRef, child as fbDbChild, get as fbDbGet, query as fbDbQuery,
  update as fbDbUpdate, remove as fbDbRemove, orderByKey, startAt, endAt,
} from 'firebase/database';
import {
  getFirestore, doc as fbFsDoc, setDoc as fbFsSetDoc, collection as fbFsCollection, getDocs as fbFsGetDocs,
} from 'firebase/firestore';
import {
  getStorage, ref as fbStRef, uploadString, getDownloadURL,
  deleteObject as fbStDeleteObject,
} from 'firebase/storage';

import * as API from '../api';
import { getNamespace } from '../utility/urls';


let config, region, _firebaseApp, _firebaseDatabase, _firebaseStorage, _firebaseFirestore;

export const injectConfig = (_config) => {
  config = _config;
};

export const injectRegion = (_region) => {
  region = _region;
};

export const getFirebaseApp = () => {
  if (!_firebaseApp) {
    const firebaseConfig = config?.firebase;
    if (!firebaseConfig) throw new Error('No firebase config');

    _firebaseApp = initializeApp(firebaseConfig);
  }

  return _firebaseApp;
};

const getFirebaseDatabase = () => {
  if (!_firebaseDatabase) {
    _firebaseDatabase = getDatabase(getFirebaseApp());
  }

  return _firebaseDatabase;
};

const getFirebaseStorage = () => {
  if (!_firebaseStorage) {
    _firebaseStorage = getStorage(getFirebaseApp());
  }

  return _firebaseStorage;
};

const getFirebaseFirestore = () => {
  if (!_firebaseFirestore) {
    _firebaseFirestore = getFirestore(getFirebaseApp());
  }

  return _firebaseFirestore;
};


const loginByEmail = async (email, password) => {
  const auth = getAuth();

  const response = await axios.post(
    `${config.apiRoot[region]}/login`,
    {
      namespace: getNamespace(),
      email,
      password,
    },
  );

  console.log('Firebase login response: ', response);

  if (response.status !== 200) {
    throw new Error('could not login');
  }

  const { token } = response.data;

  const userCredential = await signInWithCustomToken(auth, token);
  const { user: currentUser } = userCredential;

  console.log('Firebase current user: ', currentUser);

  const idtokenResult = await currentUser.getIdTokenResult(true);
  console.log('Firebase token:', idtokenResult);

  return currentUser;
};

const reauthenticateWithCredential = async (password) => {
  try {
    const auth = getAuth();
    const { currentUser } = auth;

    if (currentUser) {
      await loginByEmail(currentUser.email, password);
    }
  } catch (error) {
    throw new Error('could not login');
  }
};

const validatePassword = async (password) => {
  try {
    await reauthenticateWithCredential(password);

    return true;
  } catch (error) {
    return false;
  }
};

const updatePassword = async (currentPassword, newPassword, setCurrentUser) => {
  const currentPasswordIsValid = await validatePassword(currentPassword);

  if (currentPasswordIsValid) {
    const auth = getAuth();
    const { currentUser } = auth;

    try {
      await fbUpdatePassword(currentUser, newPassword);
      setCurrentUser(currentUser);

      return true;
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        try {
          const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);

          await fbReauthenticateWithCredential(currentUser, credential);
          await fbUpdatePassword(currentUser, newPassword);
          setCurrentUser(currentUser);

          return true;
        } catch (error2) {
          return false;
        }
      }

      console.log('updatePassword error: ', error);

      return false;
    }
  } else {
    return false;
  }
};

const logOut = async () => {
  const auth = getAuth();

  try {
    await fbSignOut(auth);

    console.log('Firebase logout successful');

    return true;
  } catch (error) {
    console.log('Firebase logout error: ', error);

    return false;
  }
};

const getIdToken = async () => {
  const auth = getAuth();
  const { currentUser } = auth;

  return currentUser ? currentUser.getIdToken() : null;
};

const sendPasswordResetEmail = async (email) => {
  try {
    const auth = getAuth();
    return await fbSendPasswordResetEmail(auth, email);
  } catch (error) {
    console.log('Password Reset Error: ', error);

    switch (error.code) {
      case 'auth/user-not-found':
        return 'User not found!';
      case 'auth/invalid-email':
        return 'Invalid email address format!';
      case 'auth/too-many-requests':
        return 'Too many attempts. Try again soon!';
      default:
        return 'Invalid attempt!';
    }
  }
};

const activateUser = async (status, id, email = null) => {
  try {
    const { data } = await API.activateUser(id);

    if (status === -1 || status === null) {
      const response = await sendPasswordResetEmail(email);

      if (response) {
        console.log('Password Reset Error');
      }
    }

    return data;
  } catch (error) {
    console.log('activateUser error: ', error);

    throw new Error(error);
  }
};

const clearCheckins = async (locationId) => {
  const namespace = getNamespace();
  const firebaseDatabase = getFirebaseDatabase();

  try {
    const revRef = fbDbRef(firebaseDatabase, `${namespace}/checkins/location/${locationId}/reviewed`);
    await fbDbRemove(revRef);

    return [];
  } catch (error) {
    console.log(error);

    return null;
  }
};

const uploadFile = async (path, fileName, body) => {
  const firebaseStorage = getFirebaseStorage();
  const fileRef = fbStRef(firebaseStorage, `namespace/${getNamespace()}/${path}/${fileName}`);
  const snapshot = await uploadString(fileRef, body, 'data_url');

  return getDownloadURL(snapshot.ref);
};

const deleteFile = async (path, fileName) => {
  const firebaseStorage = getFirebaseStorage();
  const fileRef = fbStRef(firebaseStorage, `namespace/${getNamespace()}/${path}/${fileName}`);

  await fbStDeleteObject(fileRef);
};

const uploadImage = async (path, body) => uploadFile(path, 'avatar', body);

const uploadSound = async (path, body) => uploadFile(path, 'sound', body);

const moveDatabaseItem = (rootPath, relativeFrom, relativeTo) => {
  const firebaseDatabase = getFirebaseDatabase();
  const rootRef = fbDbRef(firebaseDatabase, rootPath);
  fbDbGet(fbDbChild(rootRef, relativeFrom)).then(
    (snapshot) => {
      fbDbUpdate(rootRef, {
        [relativeFrom]: null,
        [relativeTo]: snapshot.val(),
      });
    }
  );
};

const getPendingNotificationsRef = (locationId, startDT, endDT) => {
  const firebaseDatabase = getFirebaseDatabase();
  const ref = fbDbRef(firebaseDatabase, `${getNamespace()}/notifications/location/${locationId}/pending`);
  const args = [orderByKey()];
  if (startDT) args.push(startAt(startDT));
  if (endDT) args.push(endAt(endDT));
  return fbDbQuery(ref, ...args);
};

const getReviewedNotificationsRef = (locationId, startDT, endDT) => {
  const firebaseDatabase = getFirebaseDatabase();
  const ref = fbDbRef(firebaseDatabase, `${getNamespace()}/notifications/location/${locationId}/reviewed`);
  const args = [orderByKey()];
  if (startDT) args.push(startAt(startDT));
  if (endDT) args.push(endAt(endDT));
  return fbDbQuery(ref, ...args);
};

const getNotesRef = (locationId, staffId, privateNotes) => {
  const firebaseDatabase = getFirebaseDatabase();

  return fbDbChild(
    fbDbRef(firebaseDatabase, `${getNamespace()}/notes/location/${locationId}`),
    privateNotes ? `private/${staffId}` : 'public'
  );
};

const getLocationFsDoc = (locationId) => {
  const firebaseFirestore = getFirebaseFirestore();

  return fbFsDoc(firebaseFirestore, `namespace/${getNamespace()}/locations/${locationId}`);
};

const getCollectionDocs = async (collectionName) => {
  const firebaseFirestore = getFirebaseFirestore();
  const collection = fbFsCollection(firebaseFirestore, `namespace/${getNamespace()}/${collectionName}`);
  try {
    const querySnapshot = await fbFsGetDocs(collection);
    return querySnapshot.docs.reduce((a, doc) => {
      a[doc.id] = doc.data();

      return a;
    }, {});
  } catch (error) {
    return [];
  }
};

const storeFsSound = async (uuid, name, downloadURL) => {
  const firebaseFirestore = getFirebaseFirestore();
  const ref = fbFsDoc(firebaseFirestore, `namespace/${getNamespace()}/sounds`, uuid);
  const payload = {
    name,
    downloadURL,
  };
  await fbFsSetDoc(ref, payload);
};

const deleteNotification = async (locationId, notificationKey) => {
  const firebaseDatabase = getFirebaseDatabase();
  const notificationRef = fbDbRef(firebaseDatabase, `${getNamespace()}/notifications/location/${locationId}/pending/${notificationKey}`);

  await fbDbRemove(notificationRef);
};

const firebaseService = {
  loginByEmail,
  reauthenticateWithCredential,
  validatePassword,
  updatePassword,
  logOut,
  getIdToken,
  sendPasswordResetEmail,
  activateUser,
  clearCheckins,
  uploadFile,
  deleteFile,
  uploadImage,
  uploadSound,
  moveDatabaseItem,
  getPendingNotificationsRef,
  getReviewedNotificationsRef,
  getNotesRef,
  getLocationFsDoc,
  getCollectionDocs,
  storeFsSound,
  deleteNotification,
};

export default firebaseService;
