import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import * as authActions from 'app/redux/auth/actions';
import * as authSelectors from 'app/redux/auth/selectors';
import { getRoutePath } from 'service/navigation';

import AuthRoute2 from './AuthRoute2';


class AuthRoute extends React.Component {
  checkAuthentication = () => {
    const { currentUser, setCurrentUser, history } = this.props;

    if (currentUser.isLogged) {
      return true;
    }

    let cbActive = true;

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (cbActive) {
        cbActive = false;

        if (user) {
          setCurrentUser(user);
        } else {
          history.push(getRoutePath('LOGIN'));
        }
      }
    });

    return false;
  };

  render() {
    const { currentUser, setCurrentUser, ...rest } = this.props;

    if (!this.checkAuthentication()) return null;

    return (
      <AuthRoute2 {...rest} />
    );
  }
}

AuthRoute.propTypes = {
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentUser: authSelectors.currentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(authActions.setCurrentUser(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthRoute));
