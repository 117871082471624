import { put, take, call, select } from 'redux-saga/effects';

import * as API from 'service/api';
import { getLocationLocale } from 'service/i18n';
import { setNewLocale } from 'service/utility';

import { CHANGE_LOCATION, setCurrentLocation, finishLoadingLocation } from '../actions';
import { currentLocation as currentLocationSelector } from '../selectors';
import { createNewEmptyCart } from '../../cart/sagas/cartSagas';
import { setSounds, setCheckinSettings } from '../../settings/actions';


export function *changeLocation(location) {
  const oldLocation = yield select(currentLocationSelector);
  const locationHasChanged = location.id !== (oldLocation?.id ?? null);

  yield put(setCurrentLocation(location));

  if (locationHasChanged) {
    console.log('changeLocationSaga: new location: ', location);
    yield call(createNewEmptyCart);

    const currentLocale = getLocationLocale(oldLocation);
    const newLocale = getLocationLocale(location);

    if (newLocale !== currentLocale) {
      setNewLocale(newLocale);
    }
  }

  const { data: locationSoundSettings } = yield call(API.getLocationSoundSettings, `locationIds=${location.id}`);
  yield put(setSounds(locationSoundSettings?.[0]?.setting ?? {}));

  const { data: checkinSettings } = yield call(API.getLocationCheckinSettings, location.id);
  yield put(setCheckinSettings(checkinSettings?.setting ?? {}));

  yield put(finishLoadingLocation());
}

export function *changeLocationSaga() {
  while (true) {
    const { location } = yield take(CHANGE_LOCATION);

    yield call(changeLocation, location);
  }
}
