import React from 'react';

import FileUploadDialog from '../FileUploadDialog';


const SoundUploadDialog = (props) => (
  <FileUploadDialog
    {...props}
    title="Upload Sound(s)"
    acceptedFileTypes=".mp3"
  />
);


export default SoundUploadDialog;
