import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import * as settingsActions from 'app/redux/settings/actions';
import * as stateActions from 'app/redux/state/actions';
import * as stateSelectors from 'app/redux/state/selectors';
import * as transactionActions from 'app/redux/transactions/actions';
import { ChangeLocationDialog } from 'common/dialogs';
import * as API from 'service/api';


class AuthRoute2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationCheck: false,
    };
  }

  async componentDidMount() {
    const { currentLocation, changeLocation } = this.props;

    if (currentLocation) {
      try {
        console.log(`AuthRoute2: loading Location with id = ${currentLocation.id}`);
        const { data: location } = await API.fetchLocation(currentLocation.id);

        console.log('AuthRoute2: loaded the following Location: ', location);
        changeLocation(location);
      } catch (error) {
        console.log('API.fetchLocation error: ', error);
      }
    }

    this.setState({ locationCheck: true });
  }

  loadLocationSettings = async (location) => {
    const { setCheckinSettings } = this.props;

    try {
      console.log('AuthRoute: getting location checkin settings for location with id: ', location.id);
      const { data: locationCheckinSettings } = await API.getLocationCheckinSettings(location.id);

      console.log('AuthRoute: retrieved the following location checkin settings: ', locationCheckinSettings);
      setCheckinSettings(locationCheckinSettings?.setting ?? {});
    } catch (error) {
      console.log('AuthRoute: loadSettings error: ', error);
    }
  };

  handleLocationSelect = (location) => {
    this.props.changeLocation(location);
    this.loadLocationSettings(location);
  };

  handleDeviceSelect = (device) => {
    this.props.setPaymentDevice(device);
  };

  render() {
    const { changeLocation, currentLocation, finishedInitialLocationLoad, setPaymentDevice, ...rest } = this.props;
    const { locationCheck } = this.state;

    if (!locationCheck) return null;

    if (!currentLocation) {
      return (
        <ChangeLocationDialog
          open
          onLocationSelect={this.handleLocationSelect}
          onDeviceSelect={this.handleDeviceSelect}
        />
      );
    }

    if (!finishedInitialLocationLoad) return null;

    return (
      <Route {...rest} />
    );
  }
}

AuthRoute2.propTypes = {
  changeLocation: PropTypes.func.isRequired,
  currentLocation: PropTypes.object,
  finishedInitialLocationLoad: PropTypes.bool,
  setCheckinSettings: PropTypes.func.isRequired,
  setPaymentDevice: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocation: stateSelectors.currentLocation(state),
  finishedInitialLocationLoad: stateSelectors.finishedInitialLocationLoad(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (location) => dispatch(stateActions.changeLocation(location)),
  setCheckinSettings: (settings) => dispatch(settingsActions.setCheckinSettings(settings)),
  setPaymentDevice: (payload) => dispatch(transactionActions.setPaymentDevice(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute2);
