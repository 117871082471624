import React from 'react';
import { PropTypes } from 'prop-types';

import { TICKET_STATUS_PICKLIST_VALUES } from 'constants/ticket';
import { commaJoin, somePropsChanged } from 'service/utility';

import { Checkboxes } from '../../formFields';


const STATUSES = TICKET_STATUS_PICKLIST_VALUES.map((status) => ({
  name: status.label,
  id: status.value,
}));

class TicketStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ticketStatus: (
        this.props.hasOwnProperty('status') && this.props.status !== null
          ? Array.isArray(this.props.status)
            ? this.props.status
            : this.props.status.split(',')
          : []
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['ticketStatus'])) {
      this.handleValueChange();
    }
  }

  handleStatusChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({
        ticketStatus: update.value,
      });
    }
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    status: this.state.ticketStatus.length > 0 ? (
      commaJoin(this.state.ticketStatus.map((status) => status))
    ) : null,
  });

  render() {
    const { ticketStatus } = this.state;

    return (
      <Checkboxes
        className="ticket-status-filter"
        label="Status(es)"
        options={STATUSES}
        value={ticketStatus}
        onChange={this.handleStatusChange}
      />
    );
  }
}

TicketStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  status: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
};

TicketStatusFilter.ownName = 'TicketStatusFilter';
TicketStatusFilter.ownFilters = ['status'];


export default TicketStatusFilter;
