import React from 'react';

import FileUploadDialog from '../FileUploadDialog';


const DocumentDialog = (props) => (
  <FileUploadDialog
    {...props}
    title="Upload Document(s)"
    acceptedFileTypes="application/pdf, .doc, .docx, .xlsx"
  />
);


export default DocumentDialog;
