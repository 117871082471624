import { makeActionCreator } from 'service/utility/redux';


export const SET_REGION = 'SET_REGION';
export const setRegion = makeActionCreator(SET_REGION, 'region');

export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const changeLocation = makeActionCreator(CHANGE_LOCATION, 'location');

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const setCurrentLocation = makeActionCreator(SET_CURRENT_LOCATION, 'location');

export const FINISH_INITIAL_LOCATION_LOAD = 'FINISH_INITIAL_LOCATION_LOAD';
export const finishLoadingLocation = makeActionCreator(FINISH_INITIAL_LOCATION_LOAD);
