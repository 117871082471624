import { makeActionCreator } from 'service/utility/redux';


export const SET_SETTINGS_LOADED = 'SET_SETTINGS_LOADED';
export const setSettingsLoaded = makeActionCreator(SET_SETTINGS_LOADED);

export const SET_CHECKIN_SETTINGS = 'SET_CHECKIN_SETTINGS';
export const setCheckinSettings = makeActionCreator(SET_CHECKIN_SETTINGS, 'settings');

export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';
export const setGeneralSettings = makeActionCreator(SET_GENERAL_SETTINGS, 'settings');

export const SET_DEFAULT_SETTINGS = 'SET_DEFAULT_SETTINGS';
export const setDefaultSettings = makeActionCreator(SET_DEFAULT_SETTINGS, 'settings');

export const SET_SOUND_SETTINGS = 'SET_SOUND_SETTINGS';
export const setSoundSettings = makeActionCreator(SET_SOUND_SETTINGS, 'settings');

export const SET_SOUNDS = 'SET_SOUNDS';
export const setSounds = makeActionCreator(SET_SOUNDS, 'sounds');
