const stateKey = 'state';

export const loadState = () => {
  const serializedState = localStorage.getItem(stateKey);
  if (!serializedState) return {};

  const allState = JSON.parse(serializedState);
  return allState;
};

export const saveState = (state) => {
  localStorage.setItem(stateKey, JSON.stringify({
    app: {
      currentLocation: state.app.currentLocation,
    },
    auth: {
      currentUser: {
        currentUserUID: state.auth.currentUser.currentUserUID,
        currentUserEmail: state.auth.currentUser.currentUserEmail,
        imageURL: state.auth.currentUser.imageURL,
      },
      currentStaff: state.auth.currentStaff,
      lockScreen: {
        isLocked: state.auth.lockScreen.isLocked,
      },
      activeUsers: state.auth.activeUsers,
    },
    carts: {
      activeCart: {
        UUID: state.carts.activeCart?.UUID ?? null,
      },
    },
    transactions: {
      device: state.transactions.device,
    },
    layout: {
      rightDrawer: state.layout.rightDrawer,
    },
  }));
};

export const clearLocalStorage = () => {
  localStorage.removeItem(stateKey);
};
