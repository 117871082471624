import { createReducer } from 'service/utility/redux';

import { FINISH_INITIAL_LOCATION_LOAD } from '../actions';


const initialState = false;

const finishedInitialLocationLoad = createReducer(initialState, {
  [FINISH_INITIAL_LOCATION_LOAD]: () => true,
});


export default finishedInitialLocationLoad;
