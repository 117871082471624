import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, List,
} from '@material-ui/core';

import { readAsData } from 'service/utility';

import FileUploader from '../../FileUploader';
import { Spinner, StatusOverlay } from '../../statusIndicators';
import UploadedFile from './UploadedFile';


class FileUploadDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFiles: [],
      uploading: false,
    };
  }

  handleNewUploadedFiles = async (newFiles) => {
    this.setState({
      uploading: true,
    });

    const base64Files = await Promise.all(
      newFiles.map(async (file) => {
        const base64 = await readAsData(file);

        return {
          name: file.name,
          base64,
        };
      })
    );

    this.setState({
      uploadedFiles: base64Files,
      uploading: false,
    });
  };

  cancelUpload = () => {
    this.setState({ uploadedFiles: [] });
    this.props.onCancelUpload();
  };

  handleSubmitUploads = () => {
    this.props.onFilesUploaded(this.state.uploadedFiles);
    this.cancelUpload();
  };

  removeFile = (index) => this.setState((prevState) => ({
    uploadedFiles: prevState.uploadedFiles.filter((e, i) => i !== index),
  }));

  render() {
    const { open, title, acceptedFileTypes } = this.props;
    const { uploadedFiles, uploading } = this.state;

    return (
      <Dialog
        open={open}
        classes={{ paper: 'file-upload-dialog' }}
      >
        {uploading && (
          <StatusOverlay>
            <Spinner size={60} />
          </StatusOverlay>
        )}
        <DialogTitle>
          {title}
        </DialogTitle>
        {!uploadedFiles.length ? (
          <FileUploader
            handleNewFiles={this.handleNewUploadedFiles}
            acceptedFileTypes={acceptedFileTypes}
          />
        ) : (
          <DialogContent classes={{ root: 'dialog-content' }}>
            <List>
              {uploadedFiles.map((file, idx) => (
                <UploadedFile
                  key={file.name}
                  idx={idx}
                  fileName={file.name}
                  onRemove={this.removeFile}
                />
              ))}
            </List>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={this.cancelUpload}>
            {'Cancel'}
          </Button>
          <Button
            color="primary"
            disabled={!uploadedFiles.length}
            onClick={this.handleSubmitUploads}
          >
            {'Upload'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FileUploadDialog.propTypes = {
  acceptedFileTypes: PropTypes.string,
  onCancelUpload: PropTypes.func.isRequired,
  onFilesUploaded: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

FileUploadDialog.defaultProps = {
  title: 'Upload File(s)',
};


export default FileUploadDialog;
