import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';
import { formatName } from 'service/utility/stringFormatters';
import * as timeUtils from 'service/utility/timeUtils';

import { VersionFilter } from '../../listFilters';
import { DynamicDataList } from '../../lists';
import { Spinner } from '../../statusIndicators';
import SubmissionCell from './SubmissionCell';


class SubmissionsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingQuestions: true,
      versionId: this.props.form.publishedVersionId,
      formSubmissionsConfig: [],
    };
  }

  loadQuestions = async () => {
    const { form: { id: formId }, timeZone } = this.props;
    const { versionId } = this.state;
    if (!formId || !versionId) return;

    this.setState({ loadingQuestions: true });

    try {
      const { data: formPublished } = (
        await API.getFormTemplateByFormVersionId(formId, versionId)
      );

      const questions = formPublished?.FormVersion?.Questions || [];
      const formSubmissionsConfig = [
        {
          label: 'Date Submitted',
          sortString: 'submittedAt',
          notSearchable: true,
          render: (submission) => (
            submission.submittedAt
              ? timeUtils.formatShortDateTime(submission.submittedAt, timeZone)
              : ''
          ),
        },
        {
          label: 'Name',
          sortString: 'firstname,lastname',
          render: (submission) => formatName(submission.AssignedCustomer || submission.Customer),
        },
        ...questions.map((question) => ({
          label: question.name,
          notSearchable: true,
          notSortable: true,
          render: (submission) => <SubmissionCell submission={submission} question={question} />,
        })),
      ];

      this.setState({
        formSubmissionsConfig,
        loadingQuestions: false,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.loadFormSubmissions error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      this.setState({ loadingQuestions: false });
    }
  };

  handleVersionChange = (versionId) => {
    this.setState(
      { versionId },
      this.loadQuestions,
    );
  };

  render() {
    const { currentLocation, form, getAPI, onItemClick, getParams, title } = this.props;
    const { formSubmissionsConfig, loadingQuestions, versionId } = this.state;

    const urlParams = new URLSearchParams(this.props.queryParams);
    urlParams.set('formVersionId', versionId);
    const queryParams = urlParams.toString();

    return (
      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-row align-items-end justify-content-between">
          <div>
            {Boolean(title) && (
              <Typography variant="h6">
                {title}
              </Typography>
            )}
          </div>
          <VersionFilter
            form={form}
            onValueChange={this.handleVersionChange}
            versionId={versionId}
          />
        </div>
        {loadingQuestions ? (
          <Spinner />
        ) : (
          <DynamicDataList
            currentLocation={currentLocation}
            getAPI={getAPI}
            getParams={getParams}
            queryParams={queryParams}
            fieldsConfig={formSubmissionsConfig}
            onItemClick={onItemClick}
            order="DESC"
            orderBy="submittedAt"
            filters={[]}
            useURLParams={false}
          />
        )}
      </div>
    );
  }
}

SubmissionsTable.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  getAPI: PropTypes.func.isRequired,
  getParams: PropTypes.array,
  onItemClick: PropTypes.func,
  queryParams: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
  title: PropTypes.string,
};


export default SubmissionsTable;
